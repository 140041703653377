import React, { useState, useEffect, useContext, useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { get } from '../../services/utils';
import logomarkImg from '../../assets/images/blues_logo_white.svg';
import logoImg from '../../assets/images/bluenatics_logo_white.svg';
import WhiteLogo from '../../assets/images/whiteblueslogo.svg';
import NewSvg from '../../assets/images/new.svg';
import './Menu.scss';
import MainContext from '../../contexts/MainContext';

function Menu(props) {
  const {
    condensed,
    path,
    display,
  } = props;

  const logo = useMemo(() => {
    return display === "app" ? "logo" : "logomark";
  }, [display]);

  const [scrolled, setScrolled] = useState(false);
  const { session, sessionDelete, listSubscription } = useContext(MainContext);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => event => {
    if (event) {
      event.preventDefault();
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };  

  const offersClasses = classnames('Menu-nav--condensed__link', {
    'Menu-nav--condensed__link--active': path === '/offers',
    'Menu-nav--condensed__link--unread': get(listSubscription, 'new_offers') && path !== '/offers',
  });

  const logout = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpen(false);
    sessionDelete();
  }

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

  if (condensed) {
    return (
      <div className="Menu Menu--condensed">
        <Drawer open={isOpen} anchor="right" onClose={toggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={toggleDrawer(true)}
            onKeyDown={toggleDrawer(false)}
            className="Menu-index"
          >
            <div className="Menu-list">
              <Link
                to="/"
                className={`Menu-list-item ${
                  path === "/" ? "Menu-list-item--active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/games"
                className={`Menu-list-item ${
                  path === "/games" ? "Menu-list-item--active" : ""
                }`}
              >
                My Games
              </Link>
              <a
                href="https://bluenotekidsclub.com"
                target="_blank"
                rel="noopener noreferrer"
                className="Menu-list-item"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span style={{ color: "#8ed343" }}>K</span>
                <span style={{ color: "#fbe93c" }}>I</span>
                <span style={{ color: "#4184d5" }}>D</span>
                <span style={{ color: "#d34142" }}>S</span>{" "}
              </a>
              <Link
                to="/offers"
                className={`Menu-list-item ${
                  path === "/offers" ? "Menu-list-item--active" : ""
                }`}
              >
                Offers
              </Link>
              <Link
                to="/stats"
                className={`Menu-list-item ${
                  path === '/stats' ? 'Menu-list-item--active' : ""
                }`}
              >
                My Stats
              </Link>
              <Link
                to="/account"
                className={`Menu-list-item ${
                  path === "/account" ? "Menu-list-item--active" : ""
                }`}
              >
                Account
              </Link>
              
              <div
                className="Menu-list-item Menu-list-item--logout"
                onClick={logout}
              >
                Logout
              </div>
            </div>
          </div>
        </Drawer>
        <div
          className={classnames("Menu-nav Menu-nav--condensed", {
            "Menu-nav--scrolled": scrolled,
            "Menu-nav--scrolled Menu-nav--relative": logo === "logo",
          })}
        >
          <Link to="/" className="Menu-nav__logo-link">
            {logo === "logomark" && (
              <img
                src={WhiteLogo}
                className="Menu-nav__logo-link__img--logomark"
                alt="Blues Logo"
              />
            )}
            {logo === "logo" && (
              <img
                src={WhiteLogo}
                className="Menu-nav__logo-link__img--logo"
                alt="Bluenatics"
              />
            )}
          </Link>
          <div className="flex items-center text-xs sm:text-base relative text-center">
            <Link
              to="/"
              className={`Menu-nav--condensed__link ${
                path === "/" ? "Menu-nav--condensed__link--active" : ""
              }`}
            >
              Home
            </Link>
            {/* <Link to="/account" className={`Menu-nav--condensed__link ${path === '/account' ? 'Menu-nav--condensed__link--active': ''}`}>Account</Link> */}
            <Link
              to="/games"
              className={`Menu-nav--condensed__link relative ${
                path === "/games" ? "Menu-nav--condensed__link--active" : ""
              }`}
            >
              My Games
            </Link>
            <a
              href="https://bluenotekidsclub.com"
              target="_blank"
              rel="noopener noreferrer"
              className="Menu-nav--condensed__link relative"
            >
              <span style={{ color: "#8ed343" }}>K</span>
              <span style={{ color: "#fbe93c" }}>I</span>
              <span style={{ color: "#4184d5" }}>D</span>
              <span style={{ color: "#d34142" }}>S</span>{" "}
            </a>
            <Link to="/offers" className={`${offersClasses}`}>
              Offers
            </Link>
            <Link
              to="/bars"
              className={`Menu-nav--condensed__link relative ${
                path === "/bars" ? "Menu-nav--condensed__link--active" : ""
              }`}
            >
              Bars
            </Link>
          </div>
          {session ? (
            <button onClick={toggleDrawer(true)} className="Menu-btn">
              <i className="icon-menu"></i>
            </button>
          ) : (
            <Link to="/sign-in" className="Menu-btn">
              <i className="icon-power"></i>
            </Link>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="Menu">
      <Drawer open={isOpen} anchor="right" onClose={toggleDrawer(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer(true)}
          onKeyDown={toggleDrawer(false)}
          className="Menu-index"
        >
          <div className="Menu-list">
            <Link
              to="/"
              className={`Menu-list-item ${
                path === "/" ? "Menu-list-item--active" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/games"
              className={`Menu-list-item ${
                path === "/games" ? "Menu-list-item--active" : ""
              }`}
            >
              My Games
            </Link>
            <Link
              to="/offers"
              className={`Menu-list-item ${
                path === "/offers" ? "Menu-list-item--active" : ""
              }`}
            >
              Offers
            </Link>
            <a
              href="https://bluenotekidsclub.com"
              target="_blank"
              rel="noopener noreferrer"
              className="Menu-list-item"
              title="Bluenote Kids Club"
              aria-label="Bluenote Kids Club"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span style={{ color: "#8ed343" }}>K</span>
              <span style={{ color: "#fbe93c" }}>I</span>
              <span style={{ color: "#4184d5" }}>D</span>
              <span style={{ color: "#d34142" }}>S</span>{" "}
            </a>
            <a
              href="https://www.nhl.com/blues/fans/contest-winners"
              target="_blank"
              rel="noopener noreferrer"
              className="Menu-list-item"
              title="Contest Winners"
              aria-label="Contest Winners"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
            Contest Winners
            </a>
            <Link
              to="/stats"
              className={`Menu-list-item ${
                path === "/stats" ? "Menu-list-item--active" : ""
              }`}
            >
              My Stats
            </Link>
            <Link
              to="/account"
              className={`Menu-list-item ${
                path === "/account" ? "Menu-list-item--active" : ""
              }`}
            >
              Account
            </Link>
            <div
              className="Menu-list-item Menu-list-item--logout"
              onClick={logout}
            >
              Logout
            </div>
          </div>
        </div>
      </Drawer>
      <div
        className={classnames("Menu-nav", {
          "Menu-nav--scrolled": scrolled,
          "Menu-nav--scrolled Menu-nav--relative": logo === "logo",
        })}
      >
        <Link to="/" className="Menu-nav__logo-link">
          {logo === "logomark" && (
            <img
              src={logomarkImg}
              className="Menu-nav__logo-link__img--logomark"
              alt="Blues Logo"
            />
          )}
          {logo === "logo" && (
            <img
              src={logoImg}
              className="Menu-nav__logo-link__img--logo"
              alt="Bluenatics"
            />
          )}
        </Link>
        {session ? (
          <button onClick={toggleDrawer(true)} className="Menu-btn">
            <i className="icon-menu"></i>
          </button>
        ) : (
          <Link to="/sign-in" className="Menu-btn">
            <i className="icon-power"></i>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Menu;
