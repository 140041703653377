import React from 'react';
import classNames from 'classnames';
import history from '../../history';
import './ListOffer.scss';
import { DateTime } from "luxon";

const ListOffer = (props) => {
  const {
    offer,
    className,
  } = props;

  const classes = classNames('ListOffer', className);

  const handleClick = (e) => {
    window.gtag('event', 'select_content', {
      action: 'click',
      category: 'redeem',
      content_type: 'offer',
      item_id: `${offer.id}`
    });
    const param = encodeURIComponent(window.location.pathname + window.location.search);
    history.push({ pathname: '/sign-in', search: `?redirect=${param}` });
  }

  return (
    <div className={classes}>
      <div className="bg-blues-light-blue text-white text-center p-2 nhl-medium text-xl">BLUENATICS OFFER</div>
      <div className="ListOffer-content flex flex-col justify-center text-center">
        
        {offer.sender_logo ? (
          <img src={offer.sender_logo} alt={offer.sender_name} className="ListOffer-content__sender-logo mb-3" />
        ) : (
          <div className="ListOffer-content__title title nhl-medium !text-3xl mb-3 text-center">{offer.sender_name}</div>
        )}
        <p className="ListOffer-content__body text-lg">{offer.subject}</p>
        <p className="mt-2 text-sm">EXPIRES: {DateTime.fromISO(offer.expires_at).toLocaleString(DateTime.DATETIME_FULL)}</p>
        <div className="ListOffer-content__body text-base my-4 blur">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </div>
        <button className="btn ListOffer-content__btn mx-auto" onClick={handleClick}>LOG IN FOR DETAILS</button>
      </div>
    </div>
  );
}

export default ListOffer;