import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { normalize } from 'normalizr';
import userListOffersModel from '../../models/userListOffer';
import credenzaOffersModel from '../../models/credenzaOffer';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import NavigationHeader from '../../components/NavigationHeader';
import MustBeSubscribed from '../../components/MustBeSubscribed';
import Menu from '../../components/Menu';
import UserOfferListItem from '../../components/UserOfferListItem';
import UserOffer from '../../components/UserOffer';
import Loading from '../../components/Loading';
import BluesFooter from '../../components/BluesFooter';
import Modal from '../../components/Modal';
import Empty from '../../components/Empty';
import history from '../../history';
import TS from '../../api';
import { isSmall, searchStringToHash } from '../../services/utils';
import './Offers.scss';
import MainContext from '../../contexts/MainContext';
import { getPassport } from "../../utils/passport";
import PassportOfferBg from "../../assets/images/passport-offer-inbox.png";

const Offers = (props) => {
  const { listSubscription } = useContext(MainContext);
  const passport = getPassport(listSubscription);
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const offerId = params.id;

  const offersRef = useRef();
  const [openUserOffer, setOpenUserOffer] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [userListOffers, setUserListOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [credenzaOffers, setCredenzaOffers] = useState([]);
  const [loadingCredenzaOffers, setLoadingCredenzaOffers] = useState(false);

  const userOffers = useMemo(() => {
    return [...userListOffers, ...credenzaOffers].sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  }, [userListOffers, credenzaOffers]);

  const fetchUserListOffers = async (id) => {
    setLoading(true);
    try {
      const res = await TS.get(`/user_list_offers?list_id=${id}`);
      const normalized = normalize(res.user_list_offers, [userListOffersModel]);
      const ret = Object.values(normalized.entities.user_list_offers);
      setUserListOffers(ret);
      setLoading(false);
    } catch (error) {
      // silently fail if no offers
      setLoading(false);
    }
  }

  const fetchCredenzaUserListOffers = async (id) => {
    if (!passport) return;

    setLoadingCredenzaOffers(true);
    try {
      const res = await TS.get(`/credenza_offers?list_id=${id}`);
      const normalized = normalize(res.credenza_offers, [credenzaOffersModel]);
      const ret = Object.values(normalized.entities.credenza_offers);
      setCredenzaOffers(ret);
      setLoadingCredenzaOffers(false);
    } catch (error) {
      // silently fail if no offers
      setLoadingCredenzaOffers(false);
    }
  }

  const selectUserOffer = (selectedUserOffer) => {
    setOpenUserOffer(selectedUserOffer);
    history.replace({ pathname: '/offers', search: `id=${selectedUserOffer.list_offer.id}` })
    // if mobile open modal
    window.offers_ref = offersRef.current;
    if (isSmall(offersRef.current.offsetWidth)) {
      setModalOpen(true);
    }
    window.gtag('event', 'select_content', {
      action: 'click',
      category: 'view',
      content_type: 'offer',
      item_id: selectedUserOffer.list_offer.id,
    });
  }

  useEffect(() => {
    // ideally we shouldn't need to do this check
    if (listSubscription && listSubscription.list_id) {
      fetchUserListOffers(listSubscription.list_id);
      fetchCredenzaUserListOffers(listSubscription.list_id);
    }
  }, []);

  // open first offer in list by default and track
  useEffect(() => {
    if (userOffers.length) {
      let userOffer;
      if (offerId) {
        userOffer = userOffers.find((uo) => {
          return uo.list_offer.id === offerId;
        });
      }
      if (!userOffer) {
        userOffer = userOffers[0];
      }
      setOpenUserOffer(userOffer);
      window.offers_ref = offersRef.current;
      // if desktop track view since it's visible
      // OR if it selected offer and mobile, open modal and track it automatically as well
      if (!isSmall(offersRef.current.offsetWidth) || offerId == userOffer.list_offer.id) {
        if (offerId == userOffer.list_offer.id) {
          if (isSmall(offersRef.current.offsetWidth)) {
            setModalOpen(true);
          }
        }
        window.gtag('event', 'select_content', {
          action: 'click',
          category: 'view',
          content_type: 'offer',
          item_id: userOffer.list_offer.id,
        });
      }
    }
  }, [userOffers]);

  const content = (() => {
    if (!userOffers.length) {
      return (
        <div className="Page Offers">
          <Empty className="Offers__empty" message="No Offers Available" />
        </div>
      );
    }

    return (
      <div className="Page Offers bg-white">
        <Grid container className='min-h-screen h-full'>
          <Grid className="Offers-list overflow-y-scroll" item xs={12} sm={5} md={4}>
            {!passport && (
              <a href="/passport-connect?redirect=/offers" className="" >
                <img src={PassportOfferBg} alt="Bluenatics Passport - Connect Now" />
              </a>
            )}
            {userOffers.map((userOffer) => <UserOfferListItem userOffer={userOffer} key={userOffer.id} className={`Offers-list__item ${openUserOffer?.list_offer?.id === userOffer.list_offer.id ? 'Offers-list__item--active' : ''}`} handleClick={selectUserOffer} />)}
          </Grid>
          <Grid className="Offers-open" item xs={false} sm={7} md={8}>
            {openUserOffer && <UserOffer userOffer={openUserOffer} offer={openUserOffer.list_offer} />}
          </Grid>
        </Grid>
      </div>
    );
  })();

  return (
    <MustBeSubscribed>
      <Menu path={location.pathname} />
      <NavigationHeader path={location.pathname} />
      <div ref={offersRef}>{(loading || loadingCredenzaOffers) ? <Loading /> : content}</div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        showCloseButton
      >
        {openUserOffer && (
          <UserOffer userOffer={openUserOffer} offer={openUserOffer.list_offer} />
        )}
      </Modal>
      {/* <Footer /> */}
      <BluesFooter />
    </MustBeSubscribed>
  );
}

export default Offers;