import React, { Fragment } from "react";
import Menu from "../../components/Menu";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import NavigationHeader from "../../components/NavigationHeader";
import classnames from "classnames";
import Loading from "../../components/Loading";
import Empty from "../../components/Empty";
import { searchStringToHash } from "../../services/utils";
import useRequest from "../../hooks/useRequest";

const RaffleWinners = (props) => {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const { id } = props.match.params;

  const [res] = useRequest({
    type: 'get',
    path: `/campaigns/${id}/redeemed_packages`,
  });

  const content = (() => {
    if (res.loading) {
      return <Loading />;
    } else if (res.data) {
      const { redeemed_packages, contest_preview } = res.data;
      return (
        <Fragment>
          <div className="bg-white">
            <div className="p-3">
              <h1>{contest_preview.title}</h1>
            </div>
            <ul>
              {redeemed_packages.map((rp) => (
                <li className="odd:bg-gray-50 p-3" key={rp.id}>
                  <div className="card">
                    <h2 className="text-lg">{rp.prize_package.prize_title}</h2>
                    <ul className="list-disc list-inside">
                      {rp.redemptions.map((redemption) => (
                        <li
                          key={redemption.user_name}
                          className="leading-tight"
                        >
                          {redemption.user_name} - {redemption.location}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Fragment>
      );
    }
    return <Empty message="Not Found" />;
  })();

  return (
    <Fragment>
      <Menu path={props.location.pathname} display={params.display} />
      {params.display !== "app" && (
        <NavigationHeader path={props.location.pathname} />
      )}
      <div
        className={classnames("Activity py-3 sm:pt-5 px-1", {
          "in-app": params.display === "app",
        })}
      >
        <header>
          <h1 className="logo" alt="LOGO" />
        </header>
        {content}
      </div>
      {params.display !== "app" && (
        <Fragment>
          <Footer />
          <BluesFooter />
        </Fragment>
      )}
    </Fragment>
  );
};

export default RaffleWinners;
