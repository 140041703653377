import React from "react";
import { Link } from "react-router-dom";
import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  GiftIcon,
  BellIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import CredenzaBanner from "../../assets/images/credenzabanner.png";
import CredenzaMobileBanner from "../../assets/images/credenzamobileheader.png";
import "./PassportBanner.scss";

function PassportBanner(props) {
  return (
    <div className="PassportBanner ActivityCard w-full bg-white relative">
      {/* MOBILE */}
      <div className="md:hidden">
        <img src={CredenzaMobileBanner} className="w-full h-full" />
        <div className="p-3">
          <div className="text-lg">
            <ul className="list-inside text-black text-left">
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Earn offers, rewards, and discounts based on your fandom.
              </li>
              <li className="flex items-center mb-3 text-lg text-left leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions &amp; retail at
                the Enterprise Center.
              </li>
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>Exclusive opportunities for Bluenatics!</div>
              </li>
            </ul>
          </div>

          <div className="flex items-center text-black mt-2 bg-gray-100 p-3 font-semibold text-lg rounded-md">
            <div className="inline-block mr-3">
              <i className="icon-mobile-alert text-blues-blue text-2xl"></i>
            </div>
            <div className="inline-block text-left leading-tight uppercase">
              Don't forget to enable push notifications in the{" "}
              <a
                href="https://yi.nzc.am/88/mHzrPr"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Blues App
              </a>{" "}
              so you never miss an offer!
            </div>
          </div>

          <div className="mt-6">
            <Link to="/account" className="btn w-full uppercase">
              Connect Account
            </Link>
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="hidden md:block">
        <img src={CredenzaBanner} className="w-full h-full" />
        <div className="absolute top-24 left-56">
          <div className="text-lg">
            <ul className="list-inside text-white leading-none">
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-light-blue p-2 rounded-full inline-block mr-3">
                  <BellIcon className="h-5 w-5" />
                </div>
                Notifications when tickets become available for upcoming events
              </li>
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-light-blue p-2 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions & retail at the
                Enterprise Center.
              </li>
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-light-blue p-2 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>
                  Additional exclusive access and opportunities via Bluenatics.{" "}
                  <Link
                    to="/passport-about"
                    className="inline underline link-blues-yellow"
                  >
                    Learn more
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-3">
            <Link to="/account" className="btn w-60 uppercase">
              Connect Account
            </Link>
          </div>
          <div className="inline-block text-black font-semibold uppercase bg-white rounded-full mt-3 py-2 px-3">
            <i className="icon-mobile-alert text-blues-light-blue"></i>
            <span className="ml-2">
              Enable your push notifications in the{" "}
              <a
                href="https://yi.nzc.am/88/mHzrPr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blues App
              </a>{" "}
              and never miss an offer!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassportBanner;
