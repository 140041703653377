import React from 'react';
import classNames from 'classnames';
import CopyButton from '../CopyButton';
import { get } from '../../services/utils';
import './UserOffer.scss';
import { DateTime } from "luxon";

const blue = '#004e95';

const UserOffer = (props) => {
  const {
    offer,
    userOffer,
    className,
  } = props;

  const classes = classNames('UserOffer h-full', className);

  const createBody = () => {
    return { __html: offer.body.replace(/\\n/g, '<br />').replace(/\n/g, '<br />') };
  }

  const onCopy = () => {
    window.gtag('event', 'copy', {
      action: 'click',
      category: 'coupon',
      content_type: 'offer',
      item_id: offer.id,
    });
  }

  const handleClick = (e) => {
    if (get(e, 'target') && get(e, 'target.closest')) {
      const link = e.target.closest('a');
      if (link && link.href) {
        window.gtag('event', 'select_content', {
          action: 'click',
          category: 'link',
          content_type: 'offer',
          item_id: offer.id,
          label: link.href,
        });
      }
    }
  }

  const coupon = (
    <div className="UserOffer-content-coupon mx-auto">
      <span className="UserOffer-content-coupon__code">{userOffer.coupon_code}</span>
      <CopyButton className="UserOffer-content-coupon__copy" toCopy={userOffer.coupon_code} label='' copiedLabel='' copiedCallback={onCopy} />
    </div>
  );

  return (
    <div className={classes}>
      <div className="UserOffer-content p-4 sm:p-6">
        <div className="border-b border-gray-300 border-solid pb-4 mb-6">
          <table className="text-sm text-left">
            <tbody>
              <tr>
                <th>FROM:</th>
                <td className="font-bold">{offer.sender_name}</td>
              </tr>
              <tr>
                <th>SUBJECT:</th>
                <td>{offer.subject}</td>
              </tr>
              <tr>
                <th className="pr-3">PUBLISHED:</th>
                <td>{DateTime.fromISO(userOffer.created_at).toLocaleString(DateTime.DATETIME_FULL)}</td>
              </tr>
              <tr>
                <th>EXPIRES:</th>
                <td>{DateTime.fromISO(offer.expires_at).toLocaleString(DateTime.DATETIME_FULL)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {offer.sender_logo ? (
          <img src={offer.sender_logo} alt={offer.sender_name} className="UserOffer-content__sender-logo mb-3 max-h-10 sm:max-h-20 mx-auto" />
        ) : (
          <div className="UserOffer-content__title title nhl-medium text-lg mb-3 text-center">{offer.sender_name}</div>
        )}
        {(offer.image || offer.link) && (
          <p className="UserOffer-content__body w-full mb-4 text-center" onClick={handleClick}>
            {offer.image && offer.link && (
              <a href={offer.link} target="_blank" rel="noopener noreferrer" className="inline-block mx-auto mb-4">
                <img src={offer.image} alt={offer.subject} className="UserOffer-content__image max-h-[500px]" />
              </a>
            )}
            {offer.image && !offer.link && (
              <img src={offer.image} alt={offer.subject} className="UserOffer-content__image mb-4 mx-auto max-h-[500px]" />
            )}
          </p>
        )}
        <p className="UserOffer-content__body w-full mb-4 text-center" onClick={handleClick} dangerouslySetInnerHTML={createBody()} />
        <p className="UserOffer-content__body w-full mb-4 text-center">
          {offer.link && (
            <a href={offer.link} target="_blank" rel="noopener noreferrer" className="btn sm:w-64 mx-auto">
              More Details
            </a>
          )}
          {offer.type === 'CredenzaOffer' && (
            <p className="pt-6 text-sm">* This is an exclusive offer for Bluenatics Passport holders. You will need to log in to your Passport account on the offer site for access.</p>
          )}
        </p>
        {
          userOffer.coupon_code
            ? coupon
            : null
        }
      </div>

    </div>
  );
}

export default UserOffer;