import React, { useEffect, Fragment, useMemo, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { normalize } from "normalizr";
import Loading from "../../components/Loading";
import ActivityCard from "../../components/ActivityCard";
import ActivityGrid from "../../components/ActivityGrid";
import NavigationHeader from "../../components/NavigationHeader";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import PassportBanner from "../../components/PassportBanner";
import promotionService from "../../services/promotion";
import PassportSuccessNotification from "../../components/PassportSuccessNotification";
import ENV from "../../constants";
import DashboardPlaceholderImg from "../../assets/images/dashboard-placeholder.png";
import { getPassport } from "../../utils/passport";
import activitiesModel from "../../models/activity";
import "./Dashboard.scss";
import MainContext from "../../contexts/MainContext";
import UserContestContext from "../../contexts/UserContestContext";
import TS from "../../api";

function Dashboard(props) {
  const { session, listSubscription } = useContext(MainContext);
  const { fetchUserContests } = useContext(UserContestContext);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const showPassportBanner = useMemo(() => {
    return !getPassport(listSubscription);
  }, [listSubscription]);

  const fetchActivities = async (id) => {
    setLoading(true);
    const res = await TS.get(`/activities?activity_list_id=${id}&limit=200`);
    const normalized = normalize(res.activities, [activitiesModel]);
    const ret = Object.values(normalized.entities.activities);
    setActivities(ret);
    setLoading(false);
  }

  useEffect(() => {
    promotionService.setPromotionFromQueryString();
    fetchActivities(ENV.ACTIVITY_LIST_ID);
  }, []);

  useEffect(() => {
    if (session?.id && activities.length) {
      const contestIds = activities.map((val) => val.id).join(",");
      fetchUserContests({ contest_ids: contestIds });
    }
  }, [session?.id, activities]);

  return (
    <Fragment>
      <Helmet>
        <meta name="googlebot" content="all" />
      </Helmet>
      <Menu path={props.location.pathname} />
      <NavigationHeader path={props.location.pathname} />
      {/*<WelcomeBanner />*/}
      <div className="Page dashboard py-3 sm:pt-5 px-1">
        <header>
          <h1 className="logo" alt="LOGO" />
        </header>
        {loading ? (
          <Loading />
        ) : activities.length ? (
          <ActivityGrid>
            {session?.id && showPassportBanner && <PassportBanner />}
            {activities.map((a) => (
              <ActivityCard key={a.id} activity={a} />
            ))}
          </ActivityGrid>
        ) : (
          <img
            src={DashboardPlaceholderImg}
            className="dashboard-placeholder-img"
          />
        )}
      </div>
      <Footer />
      <BluesFooter />
      <PassportSuccessNotification />
    </Fragment>
  );
}

export default Dashboard;
