import React, { useEffect, useCallback, useContext } from "react";
import MainContext from "../../contexts/MainContext";
import storage from "../../services/storage";
import TS from "../../api";
import { getPassport, getPassportInfo } from "../../utils/passport";
import { searchStringToHash } from "../../services/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function () {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const { session, sessionComplete, listSubscription, listSubscriptionUpdate } =
    useContext(MainContext);

  const onPassportConnect = useCallback(
    (passportUser) => {
      TS.post(
        `/credenza_passports?list_subscription_id=${listSubscription.id}`,
        {
          credenza_passport: {
            email: passportUser.email,
            ext_id: passportUser.sub,
            evm_address: passportUser.evm_address,
            sui_address: passportUser.sui_address,
          },
        }
      ).then((res) => {
        const updated = { ...listSubscription };
        updated.accounts.push(res);
        listSubscriptionUpdate(listSubscription.id, {
          list_subscription: updated,
        });
        // set property to be read for success notification (simpler than params)
        storage.set("passport_connected", true, {
          namespace: "credenza",
          storages: ["local"],
        });
      });
    },
    [listSubscription, listSubscriptionUpdate]
  );

  async function getInfoAndConnect() {
    if (!getPassport(listSubscription)) {
      try {
        const account = await getPassportInfo();
        await onPassportConnect(account);
      } catch (error) {
        window.top.location = params.redirect || "/";
      }
    }
  }

  useEffect(() => {
    getInfoAndConnect();
  }, []);

  useEffect(() => {
    const passport = getPassport(listSubscription);
    if (!session || !listSubscription || passport) {
      window.top.location = params.redirect || "/";
    }
  }, [session, sessionComplete, listSubscription]);

  return (
    <div className="m-3">
      <div className="bg-gray-200 p-3 md:p-8 rounded-lg flex flex-col justify-center items-center w-full h-52">
        <h2 className="text-lg font-semibold flex items-center justify-center space-x-2">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="text-blue-500">Connecting...</p>
        </h2>
        <p className="mt-1 ml-2 text-sm text-center">
          Please don't leave the page.
        </p>
      </div>
    </div>
  );
}
