import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import BluenaticsInlineBanner from "../../assets/images/bluenatics_inline_banner.png";
import "./PassportActivityBanner.scss";
import CredenzaMobileBanner from "../../assets/images/credenzamobileheader.png";

function PassportActivityBanner(props) {
  return (
    <div
      className={classnames(
        "w-full bg-white relative ActivityCard",
        props.className
      )}
    >
      {/* MOBILE */}
      <div className="md:hidden">
        <img src={CredenzaMobileBanner} className="w-full h-full" />
        <div className="p-3">
          <div className="text-lg">
            <ul className="list-inside text-black text-left">
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Earn offers, rewards, and discounts based on your fandom.
              </li>
              <li className="flex items-center mb-3 text-lg text-left leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions &amp; retail at
                the Enterprise Center.
              </li>
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>Exclusive opportunities for Bluenatics!</div>
              </li>
            </ul>
          </div>

          <div className="flex items-center text-black mt-2 bg-gray-100 p-3 font-semibold text-lg rounded-md">
            <div className="inline-block mr-3">
              <i className="icon-mobile-alert text-blues-blue text-2xl"></i>
            </div>
            <div className="inline-block text-left leading-tight uppercase">
              Don't forget to enable push notifications in the{" "}
              <a
                href="https://yi.nzc.am/88/mHzrPr"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Blues App
              </a>{" "}
              so you never miss an offer!
            </div>
          </div>

          <div className="mt-6">
            <Link to="/account" className="btn w-full uppercase">
              Connect Account
            </Link>
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <img src={BluenaticsInlineBanner} className="hidden md:block w-full h-full" />
      <div className="absolute top-16 hidden md:block">
        <div className="flex items-center p-6">
          <div className="w-full">
            {/* <img src={BluenaticsInlineBanner} className="w-96 h-full" /> */}
            <div className="mt-1">
              <div className="flex w-full items-center text-white uppercase text-sm font-bold gap-3">
                <div className="flex items-center">
                  <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                    <CurrencyDollarIcon className="h-5 w-5" />
                  </div>
                  Rewards &amp; Discounts
                </div>
                <div className="flex basis-2/5 items-center">
                  <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                    <ShoppingCartIcon className="h-5 w-5" />
                  </div>
                  Surprise offers at Enterprise Center
                </div>
                <div className="flex items-center">
                  <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                    <TicketIcon className="h-5 w-5" />
                  </div>
                  <div>Exclusive opportunities for Bluenatics</div>
                </div>
              </div>
            </div>
          </div>
          <Link to="/account" className="btn ml-6 w-60 uppercase">
            Connect Now
          </Link>
        </div>
        <div className="px-6">
          <div className="px-6 flex items-center text-black bg-gray-100 leading-tight p-1 font-semibold text-lg rounded-full">
            <div className="mr-3">
              <i className="icon-mobile-alert text-blues-blue text-2xl"></i>
            </div>
            <div className="inline w-full items-center text-left leading-none uppercase">
              Don't forget to enable push notifications in the{" "}
              <a
                href="https://yi.nzc.am/88/mHzrPr"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Blues App
              </a>{" "}
              so you never miss an offer!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassportActivityBanner;
