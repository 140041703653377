import React, { useState, Fragment, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import BasicHeader from "../../components/BasicHeader";
import Loading from "../../components/Loading/Loading";
import history from "../../history";
import {
  loginPassport,
  ignorePassport,
  passportConnectedOrIgnored,
} from "../../utils/passport";
import CredenzaMobileBanner from "../../assets/images/credenzamobileheader.png";
import {
  TicketIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  BellIcon,
} from "@heroicons/react/outline";
import "./PassportSignUp.scss";
import MainContext from "../../contexts/MainContext";
import { searchStringToHash } from "../../services/utils";

const PassportSignUp = () => {
  const location = useLocation();
  const params = searchStringToHash(location.search);

  const { session, listSubscription, listSubscriptionUpdate } =
    useContext(MainContext);
  const [working, setWorking] = useState(false);

  function redirect() {
    if (params.oauth_redirect) {
      window.top.location = params.oauth_redirect;
      return null;
    }
    history.replace(params.redirect || "/");
  }

  function handleIgnore() {
    ignorePassport();
    redirect();
  }

  async function handleLogin(e) {
    e.preventDefault();
    setWorking(true);
    await loginPassport(session?.profile?.email);
    setWorking(false);
  }

  useEffect(() => {
    if (params.passport_required && params.redirect) {
      history.replace({
        pathname: "passport-connect",
        search: `?redirect=${encodeURIComponent(params.redirect)}`,
      });
    } else if (passportConnectedOrIgnored(listSubscription)) {
      redirect();
    }
  }, [listSubscription, params]);

  return params.display === "oauth" ? (
    <div className="SignUpFlow OAuthFlow">
      <img src={CredenzaMobileBanner} className="w-full" />
      <div className="pt-6">
        <ul className="list-inside text-black text-left">
          <li className="flex items-center mb-3 text-lg leading-snug">
            <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
              <BellIcon className="h-5 w-5" />
            </div>
            Notifications when tickets become available for upcoming events
          </li>
          <li className="flex items-center mb-3 text-lg leading-snug">
            <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
              <TicketIcon className="h-5 w-5" />
            </div>
            Earn unique rewards and discounts based on your fandom
          </li>
          <li className="flex items-center mb-3 text-lg text-left leading-snug">
            <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
              <CurrencyDollarIcon className="h-5 w-5" />
            </div>
            Unlock surprise offers when using Blues Pay to purchase concessions
            &amp; retail at Enterprise Center
          </li>
          <li className="flex items-center mb-3 text-lg leading-snug">
            <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
              <ShoppingCartIcon className="h-5 w-5" />
            </div>
            <div>
              Additional exclusive access and opportunities via Bluenatics.{" "}
              <Link to="/passport-about" className="inline underline">
                Learn more
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <button className="btn w-full uppercase" onClick={handleLogin}>
          Connect Account
        </button>
        <div className="flex items-center">
          <div className="h-px bg-blues-blue w-full"></div>
          <span className="px-6 py-1 font-bold text-xl">OR</span>
          <div className="h-px bg-blues-blue w-full"></div>
        </div>
        <button
          className="btn btn--secondary w-full uppercase"
          onClick={handleIgnore}
        >
          Do This Later
        </button>
      </div>
    </div>
  ) : (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow PassportSignUp">
        <img src={CredenzaMobileBanner} className="w-full" />
        {working && (
          <Loading
            className="h-80"
            message="This could take a minute, please don't leave the page."
          />
        )}
        {!working && (
          <div className="p-6 pb-6">
            <ul className="list-inside text-black text-left">
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Earn offers, rewards, and discounts based on your fandom.
              </li>
              <li className="flex items-center mb-3 text-lg text-left leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions &amp; retail at
                the Enterprise Center.
              </li>
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>Exclusive opportunities for Bluenatics!</div>
              </li>
            </ul>

            <div className="flex items-center text-black mt-2 bg-gray-100 p-3 font-semibold text-lg rounded-md">
              <div className="inline-block mr-3">
                <i className="icon-mobile-alert text-blues-blue text-2xl"></i>
              </div>
              <div className="inline-block text-left leading-tight uppercase">
                Don't forget to enable push notifications in the{" "}
                <a
                  href="https://yi.nzc.am/88/mHzrPr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  Blues App
                </a>{" "}
                so you never miss an offer!
              </div>
            </div>

            <div className="pt-2">
              <button className="btn w-full uppercase" onClick={handleLogin}>
                Connect Account
              </button>
              <div className="flex items-center">
                <div className="h-px bg-blues-blue w-full"></div>
                <span className="px-6 py-1 font-bold text-xl">OR</span>
                <div className="h-px bg-blues-blue w-full"></div>
              </div>
              <button
                className="btn btn--secondary w-full uppercase"
                onClick={handleIgnore}
              >
                Do This Later
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default PassportSignUp;
