import React, { Fragment, useContext } from 'react';
import Menu from '../../components/Menu';
import NavigationHeader from '../../components/NavigationHeader';
import ListOffer from '../../components/ListOffer';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import useRequest from '../../hooks/useRequest';
import history from '../../history';
import { get } from '../../services/utils';
import MainContext from '../../contexts/MainContext';
import { Link } from 'react-router-dom';
import { getPassport } from "../../utils/passport";

const PassportOffer = (props) => {
  const {
    location,
  } = props;
  const { listSubscription } = useContext(MainContext);
  const passport = getPassport(listSubscription);
  const { id } = props.match.params
  const redirect = encodeURIComponent(`/passport-offer/${id}`);
  
  if (listSubscription) {
    if (passport) {
      history.replace({ pathname: '/offers', search: `?id=${id}`});
    } else {
      history.replace({ pathname: '/passport-connect', search: `?redirect=${redirect}`});
    }
  }

  return (
    <Fragment>
      <Menu path={location.pathname} />
      <NavigationHeader />
      <div className="Page p-6 max-w-2xl w-full mx-auto">
        <div className="ListOffer">
          <div className="bg-blues-light-blue text-white text-center p-2 nhl-medium text-xl">BLUENATICS PASSPORT OFFER</div>
          <div className="ListOffer-content flex flex-col justify-center text-center">
            <div className="ListOffer-content__title title nhl-medium !text-3xl mb-3 text-center">SIGN UP OR LOG IN TO ACCCESS YOUR OFFER</div>
            <p className="ListOffer-content__body text-base">* This offer is available to Bluenatics Passport holders. Log in or sign up for a free account below.</p>
            
            <div className="ListOffer-content__body text-base my-4 blur">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </div>
            <Link to={`/sign-in?redirect=${redirect}`}
             className="btn ListOffer-content__btn mx-auto">CONTINUE</Link>
          </div>
        </div>
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default PassportOffer;